
// Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);

@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic,900,900italic);

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

html,
body {
  width: 100%;
  height: 100%;
  color: #5a5a5a;
  overflow-y: auto;
  font-family: Merriweather,'Helvetica Neue',Arial,sans-serif;
}

body {
    webkit-tap-highlight-color: #222;
}

body .body-standard {
    padding-bottom: 40px;
}

body::-webkit-scrollbar {
  /* This is the magic bit */
  display: none;
}

// CUSTOMIZE THE HTML components (hr, a, h#, p)
hr {
  max-width: 50px;
  border-color: #fff;
  border-width: 3px;
}

hr.light {
  border-color: #fff;
}

a {
  color: #e18a07;
  -webkit-transition: all .35s;
  -moz-transition: all .35s;
  transition: all .35s;
}

a:hover,
a:focus {
  color: #eb3812;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans','Helvetica Neue',Arial,sans-serif;
}

p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

// Section
section {
  padding: 25px 0px;
}

.section-heading {
    margin-top: 0;
}

// Footer
footer {
  text-align: center;
}

ul {
  list-style: none;
}

// Responsive
@media(min-width:768px) {
  .container {
    width: 90%;
  }
}

header {
    position: relative;
    width: 100%;
    min-height: 90%; /* auto; */
    text-align: center;
    color: #fff;
    background-position: top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.header-content {
    position: relative;
    width: 100%;
    padding: 100px 15px;
    text-align: center;
}

.header-content .header-content-inner h1 {
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
}

.header-content .header-content-projects h2 {
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
    font-size: 16px;
}

.header-content .header-content-inner hr {
    margin: 30px auto;
}

.header-content .header-content-inner p {
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: 300;
    color: rgba(255,255,255,.7);
}

.header-content .header-content-inner a {
    color: rgba(255, 255, 255,.7);
}

@media(min-width:768px) {
    header{
        min-height: 90%;
    }

    .header-content {
        position: absolute;
        top: 50%;
        padding: 0 50px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .header-content .header-content-inner {
        margin-right: auto;
        margin-left: auto;
        max-width: 1000px;
    }

    .header-content .header-content-inner p {
        margin-right: auto;
        margin-left: auto;
        max-width: 80%;
        font-size: 18px;
    }
}